import React from "react";
import auth from "assets/auth.js";
import swal from "sweetalert2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import DesktopNavbar from "components/Navbars/DesktopNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";

// reactstrap components
import HERO from "../../assets/img/radji-choking-me.jpg";

// core components
import PopupAlert from "../../views/index-sections/PopupAlert";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      password: "",
    };
    this.handleInput = this.handleInput.bind(this);
    this.authenticateStudents = this.authenticateStudents.bind(this);
    document.title = "RBJJ | Online Academy Login";
  }
  // focus shit, not being used
  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);
  // React.useEffect(() => {
  //   document.body.classList.add("login-page");
  //   document.body.classList.add("sidebar-collapse");
  //   document.documentElement.classList.remove("nav-open");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("login-page");
  //     document.body.classList.remove("sidebar-collapse");
  //   };
  // });

  handleInput(e) {
    let value = e.target.value;
    this.setState({ [e.target.name]: value });
  }

  authenticateStudents(e) {
    e.preventDefault();
    console.log(this.state);
    if (this.state.password === "BRABO" && this.state.firstName.length >= 2) {
      swal
        .fire({
          position: "center",
          icon: "success",
          title: "Hello, " + this.state.firstName + "!",
          text: "Connecting you to the online academy now...",
          showConfirmButton: false,
          timer: 1500,
        })
        .then(
          setTimeout(
            auth.login(() => {
              this.props.history.push("/online-academy");
            }),
            10000
          )
        );
    } else {
      swal.fire({
        position: "center",
        icon: "error",
        title: "No can do!",
        text: "You need to enter your first name and the RIGHT password!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  render() {
    return (
      <>
        <DesktopNavbar />
        <div className='online-academy-header page-header page-header-large'>
          <div
            className='page-header-image'
            style={{
              backgroundImage: `url(https://scontent-lga3-1.xx.fbcdn.net/v/t1.0-9/84336016_199706378096078_2281685261282705408_o.jpg?_nc_cat=107&_nc_sid=9267fe&_nc_ohc=IvaGFVG5-Y4AX9GFKYK&_nc_ht=scontent-lga3-1.xx&oh=bb6499eb3415c4790ffa6c0476a85a94&oe=5F0B23EA)`,
            }}
          ></div>
          <Container>
            <div className='rbjj-title-container' filter-color='blue'>
              <Col className='ml-auto mr-auto' md='12'>
                <h1 className='online-academy-login-title'>
                  RBJJ | ONLINE ACADEMY
                </h1>
                <Card className='online-academy-login-card card-login card-plain'>
                  <Form action='' className='form' method=''>
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border input-lg"
                          // (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText className='input-text-icon-box'>
                            <i className='now-ui-icons users_circle-08'></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='firstName'
                          placeholder='First Name...'
                          type='text'
                          // onFocus={() => setFirstFocus(true)}
                          // onBlur={() => setFirstFocus(false)}
                          onChange={(e) => this.handleInput(e)}
                          className='input-text-entry-box'
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg "
                          // (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText className='input-text-icon-box'>
                            <i className='now-ui-icons text_caps-small'></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='password'
                          placeholder='Password...'
                          type='password'
                          className='input-text-entry-box'
                          // onFocus={() => setLastFocus(true)}
                          // onBlur={() => setLastFocus(false)}
                          onChange={(e) => this.handleInput(e)}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className='text-center'>
                      <Button
                        block
                        className='btn-round'
                        color='white'
                        onClick={this.authenticateStudents}
                        size='lg'
                        className='online-academy-login-btn'
                      >
                        Login
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default LoginPage;
