import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardGroup,
  CardSubtitle,
  CardBody,
} from "reactstrap";
import ReactPlayer from "react-player";

// core components
import DesktopNavbar from "components/Navbars/DesktopNavbar.js";
import OnlineAcademyHeader from "components/Headers/OnlineAcademyHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import VideoCard from "../index-sections/VideoCard.js";

function OnlineAcademy() {
  document.title = "RBJJ | Online Academy";
  return (
    <>
      <DesktopNavbar />
      <OnlineAcademyHeader />
      <div className='section'>
        <Container className='online-academy-gallery'>
          <Row id='online-academy'>
            <VideoCard
              title='Prof. Radji x Blue Belt'
              category='Sparring'
              staff='Professor Radji'
              desc='NoGi Sparring'
              video='prof-radji-x-blue-belt.mov'
            />
                       <VideoCard
              title='Prof. Radji x Blue Belt'
              category='Sparring'
              staff='Professor Radji'
              desc='NoGi Sparring'
              video='prof-radji-x-blue-belt.mov'
            />            <VideoCard
            title='Prof. Radji x Blue Belt'
            category='Sparring'
            staff='Professor Radji'
            desc='NoGi Sparring'
            video='prof-radji-x-blue-belt.mov'
          />            <VideoCard
          title='Prof. Radji x Blue Belt'
          category='Sparring'
          staff='Professor Radji'
          desc='NoGi Sparring'
          video='prof-radji-x-blue-belt.mov'
        />            <VideoCard
        title='Prof. Radji x Blue Belt'
        category='Sparring'
        staff='Professor Radji'
        desc='NoGi Sparring'
        video='prof-radji-x-blue-belt.mov'
      />            <VideoCard
      title='Prof. Radji x Blue Belt'
      category='Sparring'
      staff='Professor Radji'
      desc='NoGi Sparring'
      video='prof-radji-x-blue-belt.mov'
    />            <VideoCard
    title='Prof. Radji x Blue Belt'
    category='Sparring'
    staff='Professor Radji'
    desc='NoGi Sparring'
    video='prof-radji-x-blue-belt.mov'
  />            <VideoCard
  title='Prof. Radji x Blue Belt'
  category='Sparring'
  staff='Professor Radji'
  desc='NoGi Sparring'
  video='prof-radji-x-blue-belt.mov'
/>            <VideoCard
              title='Prof. Radji x Blue Belt'
              category='Sparring'
              staff='Professor Radji'
              desc='NoGi Sparring'
              video='prof-radji-x-blue-belt.mov'
            />          </Row>
        </Container>
      </div>
      <DefaultFooter />
    </>
  );
}

export default OnlineAcademy;
