import React from "react";

// reactstrap components
import { Container, Breadcrumb, BreadcrumbItem } from "reactstrap";
import VideoPlayer from "views/examples/VideoPlayer";

// core components

function VideoPlayerHeader(props) {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        // pageHeader.style.transform =
        //   "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <Breadcrumb className='video-player-breadcrumb'>
        <BreadcrumbItem>
          <a href='/online-academy'>Online Academy</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href='/online-academy'>{props.category}</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>{props.title}</BreadcrumbItem>
      </Breadcrumb>
    </>
  );
}

export default VideoPlayerHeader;
