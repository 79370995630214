import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import Schedule from "../../assets/schedule.ics";

function CovidSchedule() {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <div className='section section-tabs text-center'>
        <h2 className='title'>COVID INFORMATION</h2>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto' md='12' xl='12'>
              <h4 className='category center'>Temporary Schedule</h4>
              {/* <h6>Stay healthy. Stay improving.</h6> */}
              <Card>
                <CardHeader>
                  <Nav className='justify-content-center' role='tablist' tabs>
                    <NavItem>
                      <NavLink
                        className={iconPills === "1" ? "active" : ""}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("1");
                        }}
                      >
                        <i className='now-ui-icons ui-1_calendar-60'></i>
                        Monday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "2" ? "active" : ""}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("2");
                        }}
                      >
                        <i className='now-ui-icons ui-1_calendar-60'></i>
                        Tuesday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "3" ? "active" : ""}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("3");
                        }}
                      >
                        <i className='now-ui-icons ui-1_calendar-60'></i>
                        Wednesday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "4" ? "active" : ""}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("4");
                        }}
                      >
                        <i className='now-ui-icons ui-1_calendar-60'></i>
                        Thursday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "5" ? "active" : ""}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("5");
                        }}
                      >
                        <i className='now-ui-icons ui-1_calendar-60'></i>
                        Friday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "6" ? "active" : ""}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("6");
                        }}
                      >
                        <i className='now-ui-icons ui-1_calendar-60'></i>
                        Saturday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "7" ? "active" : ""}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("7");
                        }}
                      >
                        <i className='now-ui-icons ui-1_calendar-60'></i>
                        Sunday
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className='text-center'
                    activeTab={"iconPills" + iconPills}
                  >
                    {/* CURRENT SCHEDULE [NOV 2020] */}
                    {/*
                  =-=-==-=-==-=-==-=-==-=-==-=-==-=-==-=-==-=-= 
                   =-=-==-=-==-=-= MONDAY =-=-==-=-==-=-=
                   > 5:15pm — 6:00pm | Kids (Gi)
                   > 6:00pm - 8:00pm | Adult (Gi)
                   =-=-==-=-==-=-= TUESDAY =-=-==-=-==-=-=
                   > 12:00pm - 2:00pm | Adult (Gi)
                   > 6:00pm — 8:00pm | Adult (NoGi)
                   =-=-==-=-==-=-= WEDNESDAY =-=-==-=-==-=-=
                   > 10:00am - 12:00pm | Adult (Gi)
                   > 5:15pm — 6:00pm | Kids (Gi)
                   > 6:00pm - 8:00pm | Adult (Gi)
                   =-=-==-=-==-=-= THURSDAY =-=-==-=-==-=-=
                   > 12:00pm - 2:00pm | Adult (Gi)
                   > 5:15pm - 6:00pm | Kids Wrestling (NoGi)
                   > 6:00pm - 7:00pm | Adult Wrestling (NoGi)
                   > 7:00pm - 8:30pm | Adult (NoGi)
                   =-=-==-=-==-=-= FRIDAY =-=-==-=-==-=-=
                   > 10:00am - 12:00pm | Adult (Gi)
                   > 5:15pm — 6:00pm | Kids (Gi)
                   > 6:00pm - 8:00pm | Adult (Gi)
                   =-=-==-=-==-=-= SATURDAY =-=-==-=-==-=-=
                   > 10:00am - 12:00pm | Adult (Gi)
                   =-=-==-=-==-=-= SUNDAY =-=-==-=-==-=-=
                   > 10:00am - 12:00pm | Adult (Gi)
                  =-=-==-=-==-=-==-=-==-=-==-=-==-=-==-=-==-=-= 
                   */}

                    {/* MONDAY */}
                    <TabPane tabId='iconPills1'>
                      <p className='class'>
                        <span className='classTime'>5:15pm - 6:00pm </span>|
                        <span className='classInfo'> Kids (Gi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>6:00pm - 8:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                    </TabPane>
                    {/* TUESDAY */}
                    <TabPane tabId='iconPills2'>
                      <p className='class'>
                        <span className='classTime'>12:00pm - 2:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>6:00pm - 8:00pm </span>|
                        <span className='classInfo'> Adult (NoGi)</span>
                      </p>
                    </TabPane>
                    {/* WEDNESDAY */}
                    <TabPane tabId='iconPills3'>
                      <p className='class'>
                        <span className='classTime'>10:00am - 12:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>5:15pm - 6:00pm </span>|
                        <span className='classInfo'> Kids (Gi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>6:00pm - 8:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                    </TabPane>
                    {/* THURSDAY */}
                    <TabPane tabId='iconPills4'>
                      <p className='class'>
                        <span className='classTime'>12:00pm - 2:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>5:15pm - 6:00pm </span>|
                        <span className='classInfo'> Kids Wrestling (NoGi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>6:00pm - 7:00pm </span>|
                        <span className='classInfo'> Adult Wrestling (NoGi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>7:00pm - 8:30pm </span>|
                        <span className='classInfo'> Adult (NoGi)</span>
                      </p>
                    </TabPane>
                    {/* FRIDAY */}
                    <TabPane tabId='iconPills5'>
                      <p className='class'>
                        <span className='classTime'>10:00am - 12:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>5:15pm - 6:00pm </span>|
                        <span className='classInfo'> Kids (Gi)</span>
                      </p>
                      <p className='class'>
                        <span className='classTime'>6:00pm - 8:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                    </TabPane>
                    {/* SATURDAY */}
                    <TabPane tabId='iconPills6'>
                      <p className='class'>
                        <span className='classTime'>10:00am - 12:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                    </TabPane>
                    {/* SUNDAY */}
                    <TabPane tabId='iconPills7'>
                      <p className='class'>
                        <span className='classTime'>10:00am - 12:00pm </span>|
                        <span className='classInfo'> Adult (Gi)</span>
                      </p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>

              <p className='notif-title'>ALL STUDENTS</p>
              <p className='notif'>
                If you have not already done so, please{" "}
                <a href='https://groupme.com/join_group/53367009/Ivg39yFR'>
                  click here
                </a>{" "}
                to join the GroupMe for any updates and announcements.
              </p>

              {/* <Button
                className='mr-1 bg-info'
                color='info'
                id='tooltip243887155'
                type='button'
                href={Schedule}
              >
                Click here to download the schedule.
              </Button> */}
              {/* <UncontrolledTooltip
                delay={0}
                placement='bottom'
                target='tooltip243887155'
              >
                You can import it directly to your favorite Calender app!
              </UncontrolledTooltip> */}
              {/* <div>
                Click <a href={Schedule}>here</a> to download a copy of the
                schedule. <br />
                <a href='#' data-toggle='tooltip' title='Some tooltip text!'>
                  Hover over me
                </a>
                <div class='tooltip bs-tooltip-top' role='tooltip'>
                  <div class='arrow'></div>
                  <div class='tooltip-inner'>Some tooltip text!</div>
                </div>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CovidSchedule;
