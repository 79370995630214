import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardGroup,
  CardSubtitle,
  CardBody,
} from "reactstrap";

// import { Link } from "react-scroll";

import { Link } from "react-router-dom";

import axios from "axios";
// core components

class VideoCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let link = this.props.title.replace(/[^a-z0-9+]+/gi, "-").toLowerCase();
    return (
      <>
        <Col lg='3' md='6' sm='12' xs='12'>
          <Card className='online-academy-video-card' md='2'>
            <CardImg
              className='video-thumbnail'
              src={require("assets/img/radji-ibjjf-victory.jpg")}
              alt='Card image cap'
            />
            <span className='video-thumbnail-border'></span>
            <CardBody className='video-info'>
              <Link
                to={{
                  pathname: "/online-academy/" + link,
                  params: this.props,
                }}
              >
                <h6 className='video-title'>{this.props.title}</h6>
              </Link>
              <h6 className='video-staff'>
                {this.props.category} | {this.props.staff}
              </h6>
              <p className='video-description'>{this.props.desc}</p>
              {/* <Button
                className='video-btn'
                onClick={this.openVideo(this.props)}
              >
                OSS
              </Button> */}
            </CardBody>
          </Card>
        </Col>
        <span className='video-border'></span>
      </>
    );
  }
}

export default VideoCard;
