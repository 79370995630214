import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/css/styles.css";

// pages for this kit
import LandingPage from "views/examples/LandingPage.js";
import Radji from "views/examples/Radji.js";
import OnlineAcademy from "views/examples/OnlineAcademy.js";
import Index from "../src/views/Index.js";
import VideoPlayer from "views/examples/VideoPlayer.js";
// import LoginPage from "views/examples/LoginPage.js";
import OnlineAcademyLogin from "views/examples/OnlineAcademyLoginPage.js";
import { ProtectedRoute } from "assets/ProtectedRoute.js";
import StudentLoginPage from "views/examples/StudentLoginPage";
import StudentDashboard from "views/examples/StudentDashboard";
import auth from "assets/auth.js";



ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        {/*
          
            <Route
          path='/nucleo-icons'
          render={props => <NucleoIcons {...props} />}
        />
        <Route path='/profile' render={props => <ProfilePage {...props} />} />
        
        }*/}
        {/* <Route path='/index' render={(props) => <Index {...props} />} /> */}
        <Route
          exact
          path='/online-academy-login'
          component={OnlineAcademyLogin}
        />
        <ProtectedRoute path='/online-academy' component={OnlineAcademy} />
        <Route
          path='/online-academy/:videoLink'
          component={VideoPlayer}
        />

        <Route
          exact
          path='/student-dashboard-login'
          component={StudentLoginPage}
        />

        
        <ProtectedRoute
          path='/student-dashboard'
          component={StudentDashboard}
        />
        
        <Route
          path='/radji-bryson-barrett/'
          render={(props) => <Radji {...props} />}
        />
        <Route path='/' render={(props) => <LandingPage {...props} />} />
        <Redirect from='/*' to='/' />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
