import React from "react";
import { createRef } from "react";
import { Link, animateScroll } from "react-scroll";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

// core components

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {collapseOpen ? (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color='rbjj' expand='lg'>
        <Container>
          <div className='navbar-translate'>
            <NavbarBrand href='/' id='navbar-brand'>
              <img
                src={require("../../assets/img/rbjj-white.png")}
                width='102'
                height='102'
                className='d-inline-block align-top'
                alt='Radji Barrett Jiu Jitsu Academy'
              />
            </NavbarBrand>
            <UncontrolledTooltip target='#navbar-brand'>
              Slow is Smooth, Smooth is Fast.
            </UncontrolledTooltip>
            <button
              className='navbar-toggler navbar-toggler'
              // onClick={() => {
              //   document.documentElement.classList.toggle("nav-open");
              //   setCollapseOpen(!collapseOpen);
              // }}
              aria-expanded={collapseOpen}
              type='button'
            >
              <span className='navbar-toggler-bar top-bar'></span>
              <span className='navbar-toggler-bar middle-bar'></span>
              <span className='navbar-toggler-bar bottom-bar'></span>
            </button>
          </div>
          <Collapse
            className='justify-content-end'
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href='/#about-us'>
                  <p>About Us</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/#team'>
                  Instructors
                  {/* <Link
                    activeClass='active'
                    to='team'
                    spy={true}
                    smooth='easeInOutCubic'
                    offset={-80}
                    duration={500}
                  >
                    Instructors
                  </Link> */}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/online-academy-login'>
                  Online Academy
                  {/* <Link
                    activeClass='active'
                    to='team'
                    spy={true}
                    smooth='easeInOutCubic'
                    offset={-80}
                    duration={500}
                  >
                    Instructors
                  </Link> */}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/student-dashboard-login'>
                  Student Dashboard
                  {/* <Link
                    activeClass='active'
                    to='team'
                    spy={true}
                    smooth='easeInOutCubic'
                    offset={-80}
                    duration={500}
                  >
                    Instructors
                  </Link> */}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/#contact'>
                  Contact
                  {/* <Link
                    activeClass='active'
                    to='contact'
                    spy={true}
                    smooth='easeInOutCubic'
                    offset={-80}
                    duration={500}
                  >
                    Contact
                  </Link> */}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href='https://www.facebook.com/radjibarrettjiujitsu/'
                  target='_blank'
                  id='facebook-tooltip'
                >
                  <i className='fab fa-facebook-square'></i>
                  <p className='d-lg-none d-xl-none'>Facebook</p>
                </NavLink>
                <UncontrolledTooltip target='#facebook-tooltip'>
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href='https://www.instagram.com/radjibarrettjiujitsu'
                  target='_blank'
                  id='instagram-tooltip'
                >
                  <i className='fab fa-instagram'></i>
                  <p className='d-lg-none d-xl-none'>Instagram</p>
                </NavLink>
                <UncontrolledTooltip target='#instagram-tooltip'>
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
