import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
} from "reactstrap";
import Map from "../index-sections/Map";

// images
import F2WFinish from "../../assets/img/radji-f2w-bowandarrow.jpg";
import RNC from "../../assets/img/radji-choking-me.jpg";
import VICTORY from "../../assets/img/radji-ibjjf-victory.jpg";
import CHAMP from "../../assets/img/fw2-champ.jpg";
import TEXTLOGO from "../../assets/img/rbjj-text-logo-black.png";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CompCarousel from "../index-sections/CompCarousel";
import GymCarousel from "../index-sections/GymCarousel";
import ScheduleTabs from "../index-sections/ScheduleTabs";
import ScheduleImage from "../index-sections/ScheduleImage";
import ContactForm from "../index-sections/ContactForm";
import ContactUsPopup from "views/index-sections/ContactUsPopup.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  // const items = [
  //   { src: require("assets/img/bg1.jpg") },
  //   { src: require("assets/img/bg1.jpg") },
  //   { src: require("assets/img/bg1.jpg") }
  // ];

  return (
    <>
      <ExamplesNavbar />
      <div className='wrapper'>
        <LandingPageHeader />
        <div className='section section-about-us' id='about-us'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto text-center' md='12'>
                <h2 className='title'>About Us</h2>
                <h4 className='description'>
                  A martial arts school that is focused primarily on Brazilian
                  Jiu Jitsu, Kickboxing, Self-defense, and Fitness. We are
                  located in Fords, NJ.
                </h4>
              </Col>
            </Row>
            <div className='separator separator-primary'></div>
            <div className='section-story-overview'>
              <Row>

              <CompCarousel />

                <Col md='12'>
                  <div
                    // className='image-container image-left'
                    // style={{
                    //   backgroundImage: `url(${F2WFinish})`,
                    // }}
                    style={{
                      padding: "12px 28px 12px 28px"
                    }}>
                    <div className='blockquote blockquote-info virginio-quote'>
                      "World-class Jiu Jitsu in a friendly atmosphere. Radji is
                      an intelligent, thoughtful instructor who runs a great
                      school where competitive athletes and hobbyists alike can
                      learn and grow together. " <br></br>
                      <br></br>
                      <small>-Virginio Minervini</small>
                    </div>
                  </div>
                  <div
                    // className='image-container'
                    // style={{
                    //   backgroundImage: `url(${VICTORY})`,
                    // }}
                  ></div>
                </Col>
                <Col md='12'>
                  <div
                    // className='image-container image-right'
                    // style={{
                    //   backgroundImage: `url(${RNC})`,
                    // }}
                  ></div>
                  <h2 className="title">Training at Radji Barrett Jiu Jitsu Academy</h2>
                  <div id="main-body">
                  <p>
                    Our focus at Radji Barrett Jiu Jitsu Academy is technical
                    and innovative martial arts training with a commitment to
                    the needs and aspirations of each student.
                  </p>
                  <p>
                    We offer classes for both children and adults, along with beginners and
                    competitors. We pride ourselves on our friendly and open
                    atmosphere, with a particular focus on techincal expertise and improvement.
                  </p>
                  <p>
                    At our facility, you will find high-level teaching with a
                    wide style of concepts, coming together to form a fun yet intense training environment.
                    With our diverse mix of students and teachers, we have come
                    to create a golden community founded on our shared passion
                    for the art of Jiu Jitsu. Our student body encompasses all from hobbyists to
                    seasoned competitors. Professor Radji is at the academy
                    daily along with packed Gi, NoGi, and Kickboxing classes.
                  </p>
                  <p>We look forward to seeing you soon!</p>
                  </div>
                </Col>
                <GymCarousel />
              </Row>
            </div>
          </Container>
        </div>
        <div className='section section-team text-center' id='team'>
          <Container>
            <h2 className='title'>Our Instructors</h2>
            <div className='team'>
              <Row>
                <Col md='4'>
                  <div className='team-player'>
                    <a className='instructor-name' href='/radji-bryson-barrett'>
                      <img
                        alt='...'
                        className='rounded-circle img-fluid img-raised'
                        src={CHAMP}
                      ></img>

                      <h4 className='title'>Radji Bryson-Barrett</h4>
                    </a>
                    <p className='category text-info'>
                      Head Black Belt | Head Instructor
                    </p>
                    <p className='description'>
                      {/* Radji has been a student of martial arts for almost 20
                      years, and has been competing at a high-level in a variety
                      of sports for nearly his entire life. Throughout school,
                      he swam competitively in the Junior Olympics. In 1998, he
                      began training in Kyokushin Karate, eventually earning his
                      black belt in 2002. In 2005, he was awarded his 2nd degree
                      black belt. Today, he is a first-degree black belt in
                      Brazilian Jiu Jitsu. */}
                    </p>

                    {/* <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-instagram'></i>
                    </Button> */}
                  </div>
                </Col>
                <Col md='4'>
                  <div className='team-player'>
                    <a href='' className='instructor-name'>
                      <img
                        alt='...'
                        className='rounded-circle img-fluid img-raised'
                        src={require("assets/img/professor-joe-4.png")}
                      ></img>
                      <h4 className='title'>Joe Fiouris</h4>
                    </a>
                    <p className='category text-info'>
                      Black Belt | Instructor
                    </p>
                    <p className='description'></p>
                    {/* <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-instagram'></i>
                    </Button> */}
                  </div>
                </Col>
                <Col md='4'>
                  <div className='team-player'>
                    <a href='' className='instructor-name'>
                      <img
                        alt='...'
                        className='rounded-circle img-fluid img-raised'
                        src={require("assets/img/professor-steve.png")}
                      ></img>
                      <h4 className='title'>Steve DiSebastian</h4>
                    </a>
                    <p className='category text-info'>
                      Black Belt | Coach
                    </p>
                    <p className='description'></p>
                    {/* <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-instagram'></i>
                    </Button> */}
                  </div>
                </Col>
                <Col md='4'>
                  <div className='team-player'>
                    <a href='' className='instructor-name'>
                      <img
                        alt='...'
                        className='rounded-circle img-fluid img-raised'
                        src={require("assets/img/shlok.png")}
                      ></img>
                      <h4 className='title'>Shlok Sachdeva</h4>
                    </a>
                    <p className='category text-info'>Brown Belt | Coach</p>
                    <p className='description'></p>
                    {/* <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='https://www.instagram.com/shloksach'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-instagram'></i>
                    </Button> */}
                  </div>
                </Col>
    
                <Col md='4'>
                  <div className='team-player'>
                    <a href='' className='instructor-name'>
                      <img
                        alt='...'
                        className='rounded-circle img-fluid img-raised'
                        src={require("assets/img/shivil.png")}
                      ></img>
                      <h4 className='title'>Shivil Sachdeva</h4>
                    </a>
                    <p className='category text-info'>Brown Belt | Coach</p>
                    <p className='description'></p>
                    {/* <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='https://www.instagram.com/shloksach'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-instagram'></i>
                    </Button> */}
                  </div>
                </Col>
                
                
              </Row>
            </div>
          </Container>
        </div>

        {/* <ScheduleTabs /> */}
        <ScheduleImage />

        {/* <Carousel /> */}
{/* 
        <div className='section section-contact-us text-center' id='contact'>
          <Container>
            <ContactForm />
          </Container>
        </div> */}
        <div id="footer-contact-box" className="section-contact-us-footer">
          <h5 className='footer-contact-title'>Email</h5>
          <p className='footer-contact-info'>radjibarrettjiujitsu@gmail.com</p>
          <h5 className='footer-contact-title'>Phone Number</h5>
          <p className='footer-contact-info'>(646) 248-8020</p>
          <h5 className='footer-contact-title'>Location</h5>
          <p className='footer-contact-info'>540 New Brunswick Avenue,{"\n"}Fords NJ 08863</p>
          <ContactUsPopup />  
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
