import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Datetime from "react-datetime";
import moment from "moment";
import swal from "sweetalert2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  Container,
  Row,
} from "reactstrap";

const logo = require("../../assets/img/rbjj-white.png");

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      zip: "",
      interests: [],
      referral: "",
      date: moment(),
      nameError: false,
      emailError: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDate = this.handleDate.bind(this);
  }

  handleInput(e) {
    let value = e.target.value;
    let interests = [...this.state.interests];

    if (e.target.name === "interest") {
      interests.push({ interests: e.target.value });
      this.setState({ interests });
    } else {
      this.setState(
        { [e.target.name]: value }
        //, () => console.log(this.state)
      );
    }
  }

  handleDate = (date) => {
    let cleanDate = date;
    cleanDate = date.format("ddd, MMM Do YYYY");
    this.setState(
      {
        date: cleanDate,
      }
      //, () => console.log(this.state)
    );
  };

  validate = () => {
    let nameError;
    let emailError;

    let name = this.state.firstName + this.state.lastName;
    if (name.length < 3) nameError = true;
    if (nameError) {
      this.setState({ nameError });
    }

    if (!this.state.email.includes("@")) emailError = true;
    if (emailError) {
      this.setState({ emailError });
    }

    if (emailError || nameError) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    const isValid = this.validate();

    if (isValid) {
      this.setState({ nameError: false });
      this.setState({ emailError: false });
      let interests = this.state.interests;
      let interestsStr = interests.map((interest) => {
        interest = JSON.stringify(interest);
        interest = interest.slice(14, interest.length - 2);
        return `${interest}`;
      });

      let data = {
        name: this.state.firstName + " " + this.state.lastName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        zip: this.state.zip,
        interests: interestsStr,
        referral: this.state.referral,
        date: this.state.date,
      };

      axios({
        method: "POST",
        url:
          "https://rbjjapi-env.eba-q7nh2ygv.us-east-2.elasticbeanstalk.com/api/sendMail",
        data,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            // console.log(response);
            swal.fire({
              position: "center",
              icon: "success",
              title: response.data.statusText,
              showConfirmButton: false,
              timer: 1500,
            });
            // alert(response.data.statusText);
          } else {
            // console.log(response);
            swal.fire({
              position: "center",
              icon: "error",
              title: response.data.statusText,
              text: "Please try again, " + this.state.firstName + "...",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          swal.fire({
            position: "center",
            icon: "error",
            title: "Delivery Failed",
            text: "Please try again, " + this.state.firstName + "...",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      // console.log("Invalid Form Parameters\n", this.state);
    }
  }

  render() {
    return (
      <>
        <div>
          <Container>
            <Row>
              <Card className='card-signup' data-background-color='blue'>
                <Form action='' className='form' method=''>
                  <CardHeader className='text-center'>
                    <CardTitle className='title-up' tag='h1'>
                      Step on the mats.
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FormText className='text-muted validation-errors' color=''>
                      {this.state.nameError
                        ? "Name must be greater than 3 characters"
                        : ""}
                    </FormText>
                    <InputGroup
                    // className={
                    //   "no-border" + (firstFocus ? " input-group-focus" : "")
                    // }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='now-ui-icons users_circle-08'></i>
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder={
                          this.state.nameError
                            ? "Name must be greater than 3 characters"
                            : "First Name"
                        }
                        type='text'
                        // onFocus={() => setFirstFocus(true)}
                        // onBlur={() => setFirstFocus(false)}
                        name='firstName'
                        value={this.state.firstName || ""}
                        onChange={(e) => this.handleInput(e)}
                      ></Input>
                    </InputGroup>
                    <FormText className='text-muted validation-errors' color=''>
                      {this.state.nameError
                        ? "Name must be greater than 3 characters"
                        : ""}
                    </FormText>
                    <InputGroup
                    // className={
                    //   "no-border" + (lastFocus ? " input-group-focus" : "")
                    // }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='now-ui-icons users_circle-08'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={
                          this.state.nameError
                            ? "Name must be greater than 3 characters"
                            : "Last Name"
                        }
                        type='text'
                        // onFocus={() => setLastFocus(true)}
                        // onBlur={() => setLastFocus(false)}
                        name='lastName'
                        value={this.state.lastName || ""}
                        onChange={(e) => this.handleInput(e)}
                      ></Input>
                    </InputGroup>
                    <FormText className='text-muted validation-errors' color=''>
                      {this.state.emailError ? "Invalid Email" : ""}
                    </FormText>
                    <InputGroup
                    // className={
                    //   "no-border" + (emailFocus ? " input-group-focus" : "")
                    // }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='now-ui-icons ui-1_email-85'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={
                          this.state.emailError ? "Invalid Email" : "Email"
                        }
                        type='text'
                        // onFocus={() => setEmailFocus(true)}
                        // onBlur={() => setEmailFocus(false)}
                        name='email'
                        value={this.state.email || ""}
                        onChange={(e) => this.handleInput(e)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                    // className={
                    //   "no-border" + (emailFocus ? " input-group-focus" : "")
                    // }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='now-ui-icons tech_mobile'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Phone Number'
                        type='number'
                        // onFocus={() => setEmailFocus(true)}
                        // onBlur={() => setEmailFocus(false)}
                        name='phoneNumber'
                        value={this.state.phoneNumber || ""}
                        onChange={(e) => this.handleInput(e)}
                      >
                        <div></div>
                      </Input>
                    </InputGroup>
                    <InputGroup
                    // className={
                    //   "no-border" + (emailFocus ? " input-group-focus" : "")
                    // }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='now-ui-icons location_world'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='ZIP'
                        type='number'
                        // onFocus={() => setEmailFocus(true)}
                        // onBlur={() => setEmailFocus(false)}
                        name='zip'
                        value={this.state.zip || ""}
                        onChange={(e) => this.handleInput(e)}
                      ></Input>
                    </InputGroup>
                    <Row>
                      <Col className='form-interests' lg='4' md='6' sm='12'>
                        <InputGroup
                        // className={
                        //   "no-border" + (emailFocus ? " input-group-focus" : "")
                        // }
                        >
                          <p className='form-category'>
                            What are you interested in?
                          </p>
                          <Row className='form-interest'>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type='checkbox'
                                  name='interest'
                                  value='Brazilian Jiu Jitsu'
                                  onChange={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Brazilian Jiu Jitsu
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type='checkbox'
                                  name='interest'
                                  value='Kickboxing'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Kickboxing
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type='checkbox'
                                  name='interest'
                                  value='Self-defense'
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Self-defense
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type='checkbox'
                                  name='interest'
                                  value='Mixed Martial Arts'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Mixed Martial Arts
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type='checkbox'
                                  name='interest'
                                  value='Fitness'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Fitness
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                        </InputGroup>
                      </Col>
                      <Col className='form-interests' lg='4' md='6' sm='12'>
                        <InputGroup
                        // className={
                        //   "no-border" + (emailFocus ? " input-group-focus" : "")
                        // }
                        >
                          <p className='form-category'>
                            How did you hear about us?
                          </p>
                          <Row className='form-interest'>
                            <FormGroup check className='form-check-radio'>
                              <Label check>
                                <Input
                                  id='exampleRadios0'
                                  name='referral'
                                  type='radio'
                                  value='Facebook'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Facebook
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check className='form-check-radio'>
                              <Label check>
                                <Input
                                  id='exampleRadios1'
                                  name='referral'
                                  type='radio'
                                  value='Instagram'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Instagram
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check className='form-check-radio'>
                              <Label check>
                                <Input
                                  id='exampleRadios2'
                                  name='referral'
                                  type='radio'
                                  value='Organic'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Internet Search
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check className='form-check-radio'>
                              <Label check>
                                <Input
                                  id='exampleRadios3'
                                  name='referral'
                                  type='radio'
                                  value='Referral'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Friends/Family
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className='form-interest'>
                            <FormGroup check className='form-check-radio'>
                              <Label check>
                                <Input
                                  id='exampleRadios4'
                                  name='referral'
                                  type='radio'
                                  value='Walk-In'
                                  onClick={this.handleInput}
                                ></Input>
                                <span className='form-check-sign'></span>
                                <span className='form-interest-text'>
                                  Walk-in
                                </span>
                              </Label>
                            </FormGroup>
                          </Row>
                        </InputGroup>
                      </Col>
                      <Col className='form-interests' lg='4' md='12'>
                        <p className='form-category'>Want to try a class?</p>
                        <div className='datepicker-container'>
                          <FormGroup id='datepicker'>
                            <Datetime
                              timeFormat={false}
                              inputProps={{
                                placeholder: "Pick a day here!",
                              }}
                              name='date'
                              selected={this.state.date}
                              onChange={this.handleDate}
                              dateFormat='MM/DD/YYYY'
                            />
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className='text-center'>
                    <Button
                      className='btn-neutral btn-round'
                      color='info'
                      size='lg'
                      onClick={this.handleSubmit}
                    >
                      Send
                    </Button>
                  </CardFooter>

                  <p id='teenytiny'>If you're cool, you can train here</p>
                </Form>
              </Card>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ContactForm;
