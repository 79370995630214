import React from "react";
// react plugins that creates an input with a date picker
import Datetime from "react-datetime";
// reactstrap components
import {
  Button,
  FormGroup,
  Container,
  Modal,
  ModalBody,
  Row,
  Col,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover
} from "reactstrap";

// core components

function GetStartedPopup() {
  // const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  return (
    <>
          <Row id="modals">
            <Col md="12">
              <Button id="header-contact-btn" color="info" onClick={() => setModal2(true)}>
                Get Started Today
              </Button>                
              <Modal
                modalClassName="modal modal-neutral"
                toggle={() => setModal2(false)}
                isOpen={modal2}
              >
                {/* <div className="modal-header justify-content-center">
                  <div className="modal-profile modal-profile-info">
                    <i className="now-ui-icons health_ambulance"></i>
                  </div>
                </div> */}

                <ModalBody>
                  <p className="modal-body-text">The Jiu Jitsu Lifestyle awaits you.</p>
                  <hr></hr>
                  <p className="modal-body-text">Come in for a free Gi & NoGi class today. Contact us to schedule.</p>
                </ModalBody>
                <div className="modal-footer">
                  <Button 
                    id="modal-body-cancel-btn" 
                    
                    
                    onClick={() => setModal2(false)}>
                    Cancel
                  </Button>
                  
                  <Button
                    id="modal-body-start-btn"
                    
                    
                    href="mailto:radjibarrettjiujitsu@gmail.com?subject=NEW%20STUDENT%20INQUIRY"
                  >
                    Get Started
                  </Button>
                </div>
                <div id="modal-contact-info-left" className="modal-contact-info">
                    <span id="modal-contact-info-left">radjibarrettjiujitsu@gmail.com</span>
                    { " // "}
                    <span id="modal-contact-info-right">(646) 248-8020</span>
                </div>
                
              </Modal>
            </Col>
          </Row>
    </>
  );
}

export default GetStartedPopup;
