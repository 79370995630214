import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function RadjiHeader() {
  let pageHeader = React.createRef();
  document.title = "RBJJ | Professor Radji";

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div
        className='page-header clear-filter page-header-small'
        filter-color='blue'
      >
        <div
          className='page-header-image'
          style={{
            backgroundImage:
              "url(" + require("assets/img/radji-f2w-bowandarrow.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className='photo-container'>
            <img alt='...' src={require("assets/img/fw2-champ.jpg")}></img>
          </div>
          <h2 className='title'>Radji Bryson-Barrett</h2>
          <p className='category'>
            Founder of RBJJ | Head Instructor, Black Belt
          </p>

          <div className='content'>
            <div className='social-description'>
              <h2>7x</h2>
              <p>Fight2Win</p>
            </div>
            <div className='social-description'>
              <h2>27x</h2>
              <p>IBJJF</p>
            </div>
            <div className='social-description'>
              <h2>3x</h2>
              <p>UAEJJF</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default RadjiHeader;
