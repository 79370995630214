import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

// core components
import WebSched from "../../assets/img/schedule-web.png";
import ShareableSched from "../../assets/img/schedule-shareable.png";

function Schedule() {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  const [modal1, setModal1] = React.useState(false);
  return (
    <>
      <div id="schedule" className='section section-tabs text-center' style={{ paddingTop: "20px" }}>
        <h2 className='title'>Our Schedule</h2>
        <Container>
          <Row>
            <img id="web-schedule" src={ShareableSched} />
          </Row>
        </Container>
        <Button
                id='download-sched-btn'
                id='tooltip243887155'
                href={ShareableSched}
                download={ShareableSched}
              >
                Click here to download the schedule.
              </Button>
              <UncontrolledTooltip
                delay={0}
                placement='bottom'
                target='tooltip243887155'
              >
                It's a PNG you can save directly on your phone!
              </UncontrolledTooltip>      </div>
    </>
  );
}
export default Schedule;
