import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import HERO from "../../assets/img/nogi-nyopen.jpg";

// core components
import GetStartedPopup from "../../views/index-sections/GetStartedPopup";

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: `url(${HERO})`,
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="rbjj-title-container" filter-color="blue">
            <h1 className="rbjj-title">RADJI BARRETT JIU JITSU ACADEMY</h1>
          </div>
          {/* COVID POPUP BUTTON */}
          <GetStartedPopup />

          <Button
            id="waiver-button"
            href="javascript:void(
                      window.open(
                        'https://form.jotform.com/241068133938156',
                        'blank',
                        'scrollbars=yes,
                        toolbar=no,
                        width=700,
                        height=500'
                      )
                    )
                  "
          >
            Sign Our Waiver Here
          </Button>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
