import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// images
import F2WFinish from "../../assets/img/radji-f2w-bowandarrow.jpg";
import RNC from "../../assets/img/radji-choking-me.jpg";
import VICTORY from "../../assets/img/radji-ibjjf-victory.png";
import CHAMP from "../../assets/img/fw2-champ.jpg";
import TEXTLOGO from "../../assets/img/rbjj-text-logo-black.png";

const items = [
  {
    src:
      `${F2WFinish}`,
    altText: "Fight 2 Win",
    caption: "Fight 2 Win, Summer of 2019"
  },
  {
    src:
      `${VICTORY}`,
    altText: "Radji Barrett Jiu Jitsu",
    caption: "IBJJF Victory"
  }
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className='section' id='carousel' style={{
                      paddingBottom: "18px", paddingTop: "8px", paddingLeft: "3px", paddingRight: "3px", margin: "0 auto"
                    }}>
        <Container>
          <Row className='justify-content-center'>
            <Col lg='12' md='12'>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <img src={item.src} alt={item.altText} />
                      <div className='carousel-caption d-none d-md-block'>
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className='carousel-control-prev'
                  data-slide='prev'
                  href='#pablo'
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role='button'
                >
                  <i className='now-ui-icons arrows-1_minimal-left'></i>
                </a>
                <a
                  className='carousel-control-next'
                  data-slide='next'
                  href='#pablo'
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role='button'
                >
                  <i className='now-ui-icons arrows-1_minimal-right'></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CarouselSection;
