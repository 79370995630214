import React from "react";
import { useEffect } from 'react';
// reactstrap components
import {
  Container,
  Row,
} from "reactstrap";

import ReactPlayer from "react-player";

// core components
import VideoPlayerHeader from "components/Headers/VideoPlayerHeader.js";

const VideoPlayer = props => {
  console.log(props);

  let title;
  let category;
  let video;
  let params;

  if (!props.history.location.params) {
  title = (localStorage.getItem('title'));
  video = (localStorage.getItem('video'));
  category = (localStorage.getItem('category'));
} else {
  params = props.history.location.params;
  title = params.title;
  video = params.video;
  category = params.category
}

  useEffect(() => {
  
    document.title = `RBJJ | ${title}`;
    localStorage.setItem('title', title);
    localStorage.setItem('video', video);
    localStorage.setItem('category', category);
    }, []);

  return (
    <>
      <VideoPlayerHeader category={category} title={title} />
      <div className='video-player'>
        <ReactPlayer
          controls
          url={require(`../../assets/videos/${video}`)}
          className='video-container'
        />
        <hr />
        <Container>
          <Row>
            <div className='video-player-info'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
              ratione amet alias, quisquam consequatur ullam corporis
              dignissimos beatae omnis assumenda cupiditate in repellat, porro
              dolore. Esse consequuntur tempore laborum sapiente! Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Eos distinctio
              similique consectetur recusandae veritatis quos tenetur beatae
              dolores deleniti saepe culpa modi enim ipsum, explicabo animi,
              possimus porro veniam deserunt! Lorem, ipsum dolor sit amet
              consectetur adipisicing elit. Fugiat maiores error eaque eum vero
              numquam velit, porro sapiente odio doloribus cumque officia quam
              deleniti nulla nemo facilis hic temporibus exercitationem. Lorem
              ipsum dolor sit amet consectetur adipisicing elit. Quidem, facere
              veniam pariatur error porro quisquam itaque asperiores tenetur est
              atque commodi harum delectus. Assumenda, officia! Hic
              reprehenderit odit saepe qui?
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default VideoPlayer;
