import React from "react";
import auth from "assets/auth.js";
import swal from "sweetalert2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import DesktopNavbar from "components/Navbars/DesktopNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import CovidSchedule from "../index-sections/CovidSchedule";


// reactstrap components
import HERO from "../../assets/img/radji-choking-me.jpg";
import BGVIDEO from "../../assets/videos/GoPro-GiRolling.mp4";



// core components
import PopupAlert from "../index-sections/PopupAlert";

class StudentDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      password: "",
    };
    this.handleInput = this.handleInput.bind(this);
    this.authenticateStudents = this.authenticateStudents.bind(this);
    document.title = "RBJJ | Student Dashboard";
  }
  // focus shit, not being used
  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);
  // React.useEffect(() => {
  //   document.body.classList.add("login-page");
  //   document.body.classList.add("sidebar-collapse");
  //   document.documentElement.classList.remove("nav-open");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("login-page");
  //     document.body.classList.remove("sidebar-collapse");
  //   };
  // });

  handleInput(e) {
    let value = e.target.value;
    this.setState({ [e.target.name]: value });
  }

  authenticateStudents(e) {
    e.preventDefault();
    console.log(this.state);
    if (this.state.password === "BRABO" && this.state.firstName.length >= 2) {
      swal
        .fire({
          position: "center",
          icon: "success",
          title: "Hello, " + this.state.firstName + "!",
          text: "Connecting you to the student dashboard...",
          showConfirmButton: false,
          timer: 1500,
        })
        .then(
          setTimeout(
            auth.login(() => {
              this.props.history.push("/student-dashboard-login");
            }),
            10000
          )
        );
    } else {
      swal.fire({
        position: "center",
        icon: "error",
        title: "No can do!",
        text: "You need to enter your first name and the RIGHT password!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  render() {
    return (
      <>
        <DesktopNavbar />
        <div>
          
          <Container>
          <CovidSchedule />
          </Container>
        </div>
      </>
    );
  }
}

export default StudentDashboard;
