import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function OnlineAcademyHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        // pageHeader.style.transform =
        //   "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div
        className='page-header clear-filter page-header-online-academy'
        filter-color='blue'
      >
        <Container>
          {/* <div className='photo-container'>
            <img
              alt='...'
              src={require("assets/img/radji-ibjjf-victory.jpg")}
            ></img>
          </div> */}
          <h3 className='title'>SLOW IS SMOOTH, SMOOTH IS FAST</h3>
          {/* <div className='content'>
            <div className='social-description'>
              <h2>26</h2>
              <p>Comments</p>
            </div>
            <div className='social-description'>
              <h2>26</h2>
              <p>Comments</p>
            </div>
            <div className='social-description'>
              <h2>48</h2>
              <p>Bookmarks</p>
            </div>
          </div> */}
        </Container>
      </div>
    </>
  );
}

export default OnlineAcademyHeader;
