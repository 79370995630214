import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import RadjiHeader from "components/Headers/RadjiHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

/*
<p>Radji has been a student of martial arts for almost 20years, and competition nearly his entire life. In 1998, he began training in Kyokushin Karate, eventually earning his black belt in 2002. In 2005, he was awarded his 2nd degree black belt.</p>
<p>Radji’s competition career started as competitive swimmer that resulted in numerous state and national titles, and records which include US Open Champion, Junior National Champion, National Champion and also breaking a National High School record in the 100m Backstroke. Radji was one of the most highly recruited swimmers to come out of NJ, earning a full athletic scholarship to college. In the year 2000 he qualified for the United States Olympic Trials for the Olympic Games in Sydney, Australia. After stopping competitive swimming and beginning his journey in  Brazilian Jiu Jitsu in 2006, Radji realized there was nothing else he would rather do.</p>
<p>His love for the art was instant, and he knew this is what he wanted to spend the rest of his life doing. He not only has a tremendous passion for competition, but an equal passion for teaching. He expects a lot from his students, but he is very compassionate, understanding, encouraging and motivating for his students. He understands what it takes to reach ones goals and wants to see all of his students achieve whatever it is they desire. Radji believes Brazilian Jiu-Jitsu is something that can improve one’s life exponentially as it has done for his own life. He is a black belt under the legendary Ailson Henrique “Jucao” Brites, a 5th degree black belt in Brazilian Jiu-Jitsu. He trains directly under Jucão to continue to his progression in the art. Although he is the head instructor of Team Jucão - Edison NJ, he believes it is important to recognize that the teacher is always a student first!</p>
<h3 class="moduleTitle ">COACH RADJI'S BJJ ACCOMPLISHMENTS</h3>
<ul class="dantlist">
 <li class="firstItem">2014
2nd Place - BJJ Tour Brown Belt
3rd Place - BJJ Tour Brown Belt Open Division</li>
 <li>2013
1st Place - IBJJF NY International Summer Open-Purple Belt
1st Place - IBJJF Pan-American No Gi Championships-Purple Belt
1st Place - IBJJF Pan-American No Gi Championships-Purple Belt Open Class</li>
 <li>2012
2nd Place - IBJJF NY International Open-Blue Belt</li>
 <li>2011
1st Place - Renzo Gracie Invitational-Blue Belt</li>
 <li>2010
1st Place - NAGA Grappling World Championships-Blue Belt
1st Place - Renzo Gracie Invitational-Blue Belt
2nd Place - Renzo Gracie Invitational-Blue Belt Open Class</li>
 <li>2009
1st Place - New Jersey Brazilian Jiu-Jitsu Federation-Blue Belt
2nd Place - Grapplers Quest Nationals-Blue Belt</li>
 <li class="lastItem">2008
1st Place - NAGA Grappling Championships-Blue Belt
3rd Place - NAGA Grappling Championships-Blue Belt</li>
</ul>
*/

/*
2008

NAGA Grappling Championships
1st Blue Belt

NAGA
3rd Blue Belt

2009

NJBJJF
2nd Blue Belt

Grappler’s Quest Nationals 
2nd Blue Belt

2010

NAGA World Championships
1st Blue Belt

Renzo Gracie Invitational 
1st Gi Medium Heavy 
2nd Gi Open 

2011

Renzo Gracie Invitational 
1st Heavyweight Blue Belt 

2012

IBJJF NY International Open 
2nd Gi Heavyweight - Purple Belt 

2013

IBJJF NY Spring Open
2nd Heavyweight

IBJJF NY Summer Open
1st Gi Heavyweight 
1st Gi Open

IBJJF No Gi Pans
1st Heavyweight
1st Open 

2014

IBJJF BJJ Pro NY
1st Gi Heavyweight 
2nd Gi Open

BJJ Tour
2nd Gi Heavyweight
3rd Gi Open

IBJJF No Gi Pans
2nd Heavyweight

IBJJF Boston Summer Open
2nd Gi Heavyweight 

IBJJF NY Summer Open
2nd Gi Heavyweight 
1st Open

2015

IBJJF NY Spring Open (No Gi)
1st Heavyweight 
1st Open

IBJJF NY Spring Open (Gi)
1st Heavyweight 
2nd Open 

IBJJF European Championships 
1st Gi Heavyweight 

IBJJF BJJ Pro NY
3rd Gi Heavyweight 

IBJJF NY Summer Open (Gi)
1st Gi Heavyweight 

IBJJF NY Summer Open (No Gi)
1st Heavyweight 
3rd Open

2016 

IBJJF NY Spring Open
1st Gi Heavy
2nd Gi Open

IBJJF NY Spring Open No Gi
3rd Heavyweight 
3rd Open

BJJ Tour
2nd Heavy Gi
2nd Open Gi

IBJJF NY Summer Open No-Gi 
2nd Heavy 

IBJJF No Gi Pans
2nd Heavy
3rd Open

IBJJF NY BJJ Pro
1st Heavy Gi
2nd Open Gi

2017

UAEJJF NY Pro International
2nd Gi Heavy

IBJJF NY Spring Open
1st Heavy Gi
2nd Open Division Gi 

IBJJF NY Spring Open No Gi
2nd Heavy 

Fight To Win Pro 43 Superfight
Winner - Submission (clock choke)

Fight To Win Pro 51 Superfight
Winner - Decision 

2018

UAEJJF Continental Pro
1st Heavyweight Gi

Fight to Win Pro 71
Winner - Decision

IBJJF NY Spring Open - Gi
1st Heavyweight 
3rd Open

IBJJF NY Spring Open - No Gi
1st Super Heavyweight 
1st Open (closed out with Vanderson Gomes)

Fight to Win Pro 81
Winner - Submission (head and arm choke)

IBJJF No Gi Pans
3rd Heavyweight 
2nd Open 

Fight 2 Win Pro 90
Winner - Submission (clock choke)

2019

IBJJF European Championships
3rd Heavyweight 

UAEJJF North America Continental Pro
2nd 94kg

IBJJF NY Spring Open
2nd Heavyweight 

IBJJF NY Spring Open No Gi
2nd Super Heavy

Fight 2 Win Pro 119
Winner - Bow & Arrow Choke

2020

Fight 2 Win Pro 134
Winner - Split Decision (Light-Heavyweight CHAMPION)
*/

function Radji() {
  const [pills, setPills] = React.useState("2019");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className='wrapper'>
        <RadjiHeader />
        <div className='section'>
          <Container>
            <div className='button-container'>
              <Button
                className='btn-round btn-icon'
                color='info'
                id='tooltip340339231'
                size='lg'
                href='https://www.instagram.com/radjibarrettjiujitsu/'
              >
                <i className='fab fa-instagram'></i>
              </Button>
              <UncontrolledTooltip delay={0} target='tooltip340339231'>
                Follow me on Instagram
              </UncontrolledTooltip>
            </div>
            <h3 className='title'>About me</h3>
              <div id="radji-main-body">
              <p>
                Professor Radji has been a student of martial arts for over 20
                years. Radji's martial arts training started in 1998 when he
                began training in Kyokushin Karate, eventually earning his black
                belt in 2002. In 2005, he was awarded his 2nd degree black belt.
              </p>
              <p>
                Professor Radji’s competitive career started at a early age in
                swimming that resulted in numerous state and national titles,
                along with records such as US Open Champion, Junior National
                Champion, National Champion and also breaking a National High
                School record in the 100m Backstroke. Radji was one of the most
                highly recruited swimmers to come out of New Jersey, earning a
                full athletic scholarship to college. In the year 2000 he
                qualified for the United States Olympic Trials for the Olympic
                Games in Sydney, Australia. After stopping competitive swimming
                and having the desire to branch out his martial arts knowledge,
                he began his journey in Brazilian Jiu Jitsu in 2005. Shortly
                afterwards, Radji realized there was nothing else he would
                rather do.
              </p>
              <p>
                His love for the art was instant, and he knew this is what he
                wanted to spend the rest of his life doing. He not only has a
                tremendous passion for competition, but an equal passion for
                teaching. He expects a lot from his students, but he is very
                compassionate, understanding, encouraging and motivating. He
                understands what it takes to reach ones goals and wants to see
                all of his students achieve whatever it is they desire. Radji
                believes Brazilian Jiu-Jitsu is something that can improve one’s
                life exponentially as it has done for his own life. He received
                his black belt in 2015 from Ailson “Jucao” Brites. Radji is now
                proudly affiliated with TAC Team, headed by Jared Weiner.
              </p>
              </div>
            
            <Row>
              <Col className='ml-auto mr-auto' md='6'>
                <h4 className='title text-center'>Accomplishments</h4>
                <div className='nav-align-center'>
                  <Nav
                    className='nav-pills-info nav-pills-just-icons'
                    pills
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "2020" ? "active" : ""}
                        href='#pablo'
                        onClick={e => {
                          e.preventDefault();
                          setPills("2020");
                        }}
                      >
                        <i className=''>2020</i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2019" ? "active" : ""}
                        href='#pablo'
                        onClick={e => {
                          e.preventDefault();
                          setPills("2019");
                        }}
                      >
                        <i className=''>2019</i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2018" ? "active" : ""}
                        href='#pablo'
                        onClick={e => {
                          e.preventDefault();
                          setPills("2018");
                        }}
                      >
                        <i className=''>2018</i>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>

              <TabContent className='gallery' activeTab={"pills" + pills}>
                <TabPane tabId='pills2020'>
                  <Col className='ml-auto mr-auto' md='10'>
                    <Row className='collections accomplishments'>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://i2.wp.com/www.2300arena.com/wp-content/uploads/2018/09/Fight2WinLogoWhite.png?fit=800%2C613&ssl=1'
                          />
                          <div className='trophy-details'>
                            <h3 className='trophy-title'>
                              Fight 2 Win Pro 134
                            </h3>
                            <p className='trophy-text'>
                              Winner - Light Heavyweight Championship
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId='pills2019'>
                  <Col className='ml-auto mr-auto' md='10'>
                    <Row className='collections accomplishments'>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://upload.wikimedia.org/wikipedia/en/thumb/0/0c/International_Brazilian_Jiu-Jitsu_Federation_logo.svg/1200px-International_Brazilian_Jiu-Jitsu_Federation_logo.svg.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>European Championships</h3>
                            <p class='trophy-text'>Third Place - Heavyweight</p>
                          </div>
                        </div>

                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://events.uaejjf.org/pictures/t/107422-7mep/uae-jiu-jitsu-federation.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>
                              UAEJJF North America Continental Pro
                            </h3>
                            <p class='trophy-text'>
                              Second Place - 94 Kilograms
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://upload.wikimedia.org/wikipedia/en/thumb/0/0c/International_Brazilian_Jiu-Jitsu_Federation_logo.svg/1200px-International_Brazilian_Jiu-Jitsu_Federation_logo.svg.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>IBJJF NY Spring Open</h3>
                            <p class='trophy-text'>
                              Second Place - Heavyweight
                            </p>
                          </div>
                        </div>

                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://upload.wikimedia.org/wikipedia/en/thumb/0/0c/International_Brazilian_Jiu-Jitsu_Federation_logo.svg/1200px-International_Brazilian_Jiu-Jitsu_Federation_logo.svg.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>
                              IBJJF NY Spring Open NoGi
                            </h3>
                            <p class='trophy-text'>
                              Second Place - Super Heavyweight
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://i2.wp.com/www.2300arena.com/wp-content/uploads/2018/09/Fight2WinLogoWhite.png?fit=800%2C613&ssl=1'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>Fight 2 Win Pro 119</h3>
                            <p class='trophy-text'>
                              Winner - Bow & Arrow Choke
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId='pills2018'>
                  <Col className='ml-auto mr-auto' md='10'>
                    <Row className='collections accomplishments'>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://events.uaejjf.org/pictures/t/107422-7mep/uae-jiu-jitsu-federation.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>UAEJJF Continental Pro</h3>
                            <p class='trophy-text'>
                              First Place - Heavyweight Gi
                            </p>
                          </div>
                        </div>

                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://i2.wp.com/www.2300arena.com/wp-content/uploads/2018/09/Fight2WinLogoWhite.png?fit=800%2C613&ssl=1'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>Fight 2 Win Pro 71</h3>
                            <p class='trophy-text'>Winner - Decision</p>
                          </div>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://upload.wikimedia.org/wikipedia/en/thumb/0/0c/International_Brazilian_Jiu-Jitsu_Federation_logo.svg/1200px-International_Brazilian_Jiu-Jitsu_Federation_logo.svg.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>
                              IBJJF NY Spring Open - Gi
                            </h3>
                            <p class='trophy-text'>
                              First Place - Heavyweight | Third Place - Open
                              Weight
                            </p>
                          </div>
                        </div>

                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://upload.wikimedia.org/wikipedia/en/thumb/0/0c/International_Brazilian_Jiu-Jitsu_Federation_logo.svg/1200px-International_Brazilian_Jiu-Jitsu_Federation_logo.svg.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>
                              IBJJF NY Spring Open - NoGi
                            </h3>
                            <p class='trophy-text'>
                              First Place - Super Heavyweight | First Place -
                              Open Weight
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://i2.wp.com/www.2300arena.com/wp-content/uploads/2018/09/Fight2WinLogoWhite.png?fit=800%2C613&ssl=1'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>Fight 2 Win Pro 81</h3>
                            <p class='trophy-text'>Winner - Head & Arm Choke</p>
                          </div>
                        </div>

                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://upload.wikimedia.org/wikipedia/en/thumb/0/0c/International_Brazilian_Jiu-Jitsu_Federation_logo.svg/1200px-International_Brazilian_Jiu-Jitsu_Federation_logo.svg.png'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>IBJJF NoGi Pans</h3>
                            <p class='trophy-text'>
                              Third Place - Heavyweight | Second Place - Open
                              Weight
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className='trophy'>
                          <img
                            alt='...'
                            className='trophy-bg'
                            src='https://i2.wp.com/www.2300arena.com/wp-content/uploads/2018/09/Fight2WinLogoWhite.png?fit=800%2C613&ssl=1'
                          />
                          <div class='trophy-details'>
                            <h3 class='trophy-title'>Fight 2 Win Pro 90</h3>
                            <p class='trophy-text'>Winner - Clock Choke</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
              </TabContent>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Radji;
