import React from "react";
// react plugins that creates an input with a date picker
import Datetime from "react-datetime";
// reactstrap components
import {
  Button,
  FormGroup,
  Container,
  Modal,
  ModalBody,
  Row,
  Col,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover
} from "reactstrap";

// core components

function PopupAlert() {
  // const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  return (
    <>
          <Row id="modals">
            <Col md="12">
              <Button color="danger" onClick={() => setModal2(true)}>
                COVID-19 Announcement
              </Button>                
              <Modal
                modalClassName="modal modal-neutral"
                toggle={() => setModal2(false)}
                isOpen={modal2}
              >
                {/* <div className="modal-header justify-content-center">
                  <div className="modal-profile modal-profile-info">
                    <i className="now-ui-icons health_ambulance"></i>
                  </div>
                </div> */}

                <ModalBody>
                  <p>Due to the current circumstances, the academy practicing guidelines with limited class availability.</p>
                  <hr></hr>
                  If you're interested in training, and for more information, please contact us.
                </ModalBody>
                <div className="modal-footer">
                  <Button className="btn-info" color="link" type="button" onClick={() => setModal2(false)}>
                    Close
                  </Button>
                  <Button
                    className="btn-info"
                    color="link"
                    type="button"
                    href="mailto:radjibarrettjiujitsu@gmail.com?subject=COVID-19%20INQUIRY"
                  >
                    Contact
                  </Button>
                </div>
              </Modal>
            </Col>
          </Row>
    </>
  );
}

export default PopupAlert;
